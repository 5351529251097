import { GLOBALS } from '../config'

const DEFAULTS = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

export const componentFetch = (method, route, payload, cb, errorCb = null) => {
  if (!method || typeof method !== "string") throw new Error("fetch must have a string method.")
  if (!route || typeof route !== "string") throw new Error("fetch route must be a string.")
  if (payload && typeof payload !== "object") throw new Error("fetch payload must be an object.")
  if (cb && typeof cb !== "function") throw new Error("fetch callback must be a function.")

  let opts = { ...DEFAULTS, method }
  if (method !== 'GET') {
    opts.body = JSON.stringify(payload)
  }

  fetch(`${GLOBALS.API_URL}/${route}`, opts)
    .then(res => {
      const ok = res.ok
      res.json().then(json => {
        if (ok) {
          if (cb && typeof cb === 'function') {
            cb(json)
          }
        }
        else {
          if (errorCb && typeof errorCb === 'function') {
            errorCb(json)
          }
        }
      })
    })
    .catch(errorCb)
}


export const get = (route, cb) => {
  componentFetch('GET', route, null, cb)
}