export const GLOBALS = {
  ENV:  "staging",
  URL: "https://staging-ops.cinesend.com",
  API_URL: "https://staging-api.cinesend.com/api",
  ACCOUNT_REDIRECT_URL: "https://staging-accounts.cinesend.com/login",
  CHROME_CAST_STYLESHEET_URL: "https://s3.amazonaws.com/bitmovin-cast-css/development/styles.min.css",
  S3_PUBLIC: "https://cinesend-public-assets.s3.amazonaws.com",
  S3_ACCESS_KEY: "AKIA2DLCUU6UBE7RZGK4",
  S3_ACCELERATE_ENDPOINT: "https://cinesendcontainer-beta.s3-accelerate.amazonaws.com",
  BITMOVIN_API_KEY: "2a8b1f24-2c3c-4ab1-adeb-2b89af5171f0",
}